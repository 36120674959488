<template>

  <div>
    <Modal  @close="$emit('cerrar')" class="bpb-modal">
      <h2 class="title">Editar Lista</h2>
      <div class="body">
        <div class="row form-group">
          <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
          <div class="col-sm-10"><input v-model="lista.nombre" type="text" name="nombre" id="nombre" class="form-control" autocomplete="off"></div>
        </div>

        <div class="container">
          <nav class="navbar navbar-light bg-light justify-content-between m-0">
            <div class="navbar-brand">Opciones de la lista</div>
            <button class="btn btn-primary btn-sm" type="button" @click="openModalAgregarOpcion = true">Agregar Opción</button>
          </nav>

          <table class="table table-sm table-striped">
            <thead>
            <tr class="bg-dark">
              <th scope="col">Nombre</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in lista.opciones" :key="item.id">
              <th scope="row">
                <div class="input-group" v-if="opcion_editar.id && opcion_editar.id === item.id">

                  <input v-model="opcion_editar.valor" type="text" name="nombre" id="nombre" class="form-control" autocomplete="off">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-outline-secondary btn-sm rounded-0" @click="set_opcion_editar()">
                      <i class="icon-cancel-circle"></i>
                    </button>
                  </div>
                </div>
                <span v-else>{{item.valor}}</span>
              </th>
              <td class="text-center" v-if="item.id">
                <button type="button" class="btn btn-link btn-sm rounded-0"
                        v-if="item.id !== opcion_editar.id"
                        :class="(opcion_editar.id && opcion_editar.id !== item.id) && 'text-grey'"
                        :disabled="(opcion_editar.id && opcion_editar.id !== item.id)"
                        @click="set_opcion_editar(item)">
                  <i class="icon-pencil"></i>
                </button>
                <button v-else type="button" class="btn btn-link text-success btn rounded-0" @click="editarOpcion()">
                  <i class="icon-checkmark"></i>
                </button>
                <button class="btn btn-sm btn-link text-dark rounded-0 ml-2" :disabled="opcion_editar.id || opcion_eliminar.id" @click="set_opcion_eliminar(item)">
                  <i class="icon-cross"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-end">
            <div class="col-sm-3">
              <button class="btn principal-btn"  @click="editarLista()" :disabled="opcion_editar.id || opcion_eliminar.id">Guardar</button>
            </div>
            <div class="col-sm-3">
              <button class="btn complementary-btn" @click="$emit('cerrar')">Cancelar</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Modal v-if="openModalEliminar" :options="{width: '30vw',type:'error'}" >
      <div class="title">Eliminar opción de la lista</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás la siguiente opción de la lista: <strong>{{ opcion_eliminar.valor }}</strong> <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-4 offset-sm-4"><button class="btn secondary-btn" type="button" @click="eliminarOpcion">Continuar</button></div>
          <div class="col-sm-4"><button class="btn danger-btn" type="button" @click="set_opcion_eliminar()">Cancelar</button></div>
        </div>
      </div>
    </Modal>

  <Modal v-if="openModalAgregarOpcion" :options="{width: '40vw'}" >
      <div class="title">Agregar opción a la lista </div>
      <div class="body">
        <div class="row form-group">
          <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
          <div class="col-sm-10"><input v-model="nuevo_opcion" type="text" name="nuevo_opcion" id="nuevo_opcion" class="form-control" autocomplete="off"></div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-4 offset-sm-4"><button class="btn secondary-btn" type="button" @click="agregarOpcion()">Guardar</button></div>
          <div class="col-sm-4"><button class="btn complementary-btn" type="button" @click="openModalAgregarOpcion = false">Cancelar</button></div>
        </div>
      </div>
    </Modal>
  </div>


</template>

<script>
import api from '@/apps/cobranza_admin/api/listas.api'
import Modal from '@/components/Modal'


export default {
  name: "EditarLista",
  props:{
    lista_id: null
  },
  data(){
    return {
      lista: {
        id: null,
        nombre: null,
        opciones: [{id: null, valor: null}]
      },
      openModalEliminar: false,
      openModalAgregarOpcion: false,
      opcion_editar: { lista_id: null, id: null, valor: null },
      opcion_eliminar: { lista_id: null, id: null, valor: null },
      nuevo_opcion: null
    }
  },
  components:{
    Modal
  },
  mounted() {
   this.getLista();
  },
  watch: {
    lista_id(current, old) {
      if(current !== undefined) {
        this.getLista();
      }
    }
  },
  methods: {
     async getLista() {
      let response = await api.lista(this.lista_id);
      this.lista = response.data;
    },

    set_opcion_editar(opcion = { lista_id: null, id: null, valor: null }){
       this.opcion_editar = {...opcion};
    },

    set_opcion_eliminar(opcion = { lista_id: null, id: null, valor: null }){
      this.opcion_eliminar = opcion;
      this.openModalEliminar = (opcion.id);
    },

    async editarLista(){
      await api.editarLista(this.lista.id, {nombre: this.lista.nombre}).then(response => {
        this.lista.nombre = response.data.nombre;
        this.$emit('cerrar')
        this.$emit('actualizar')
      });
    },

    async editarOpcion(){
      await api.editarOpcion(this.opcion_editar.lista_id, this.opcion_editar.id, {valor: this.opcion_editar.valor}).then(response => {
        this.lista.opciones = response.data.opciones;
        this.set_opcion_editar();
        this.openModalEliminar = false;
      });
    },

    async agregarOpcion(){
      await api.agregarOpcion(this.lista.id, {valor: this.nuevo_opcion}).then(response => {
        this.lista.opciones = response.data.opciones;
        this.nuevo_opcion = null;
        this.openModalAgregarOpcion = false;
      });
    },

    async eliminarOpcion(){
      await api.eliminarOpcion(this.opcion_eliminar.lista_id, this.opcion_eliminar.id).then(response => {
        this.lista.opciones = response.data.opciones;
        this.set_opcion_eliminar();
      });
    },

  }
}
</script>

<style scoped>

</style>